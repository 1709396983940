* {
    box-sizing: border-box;
}

html {
    padding: 0;
    margin: 0;
    height: 100%;
}

body {
    background: hsla(211, 94%, 6%, 1);
    color: white;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    height: 100%;
    position: relative;
    font-family: 'Roboto', sans-serif;
}

@import "styles/header.scss";
@import "styles/footer.scss";

main {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    justify-items: center;

    section {
        padding: 0 20px;
    }

    margin-bottom: 180px;
}

.section-title {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;

    margin-top: 135px;

    &__title {
        font-size: 12vw;

        @media screen and (min-width: 768px) {
            font-size: 4rem;
        }

        margin-bottom: 0;
    }

    &__subtitle {
        font-size: 2rem;
    }
}

.section-subtitle {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;

    h2 {
        font-size: 2rem;
    }
}

.section-form {
    display: grid;
    grid-template-columns: 1fr;

    @media screen and (min-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    grid-auto-rows: max-content;
    gap: 15px;

    .form-description {
        @media screen and (min-width: 768px) {
            grid-column: 1 / 3;
        }
    }
}

.section--container {
    max-width: 900px;
    width: 100%;
    position: relative;
}

.section--wide {
    width: 100%;
}

.section--end {
    margin-bottom: 125px;
}

.section-best-price {
    margin-top: 65px;
    display: flex;
    flex-direction: column;
    align-items: center;

    hr {
        width: 65%;
        margin: 35px 0;
        border-color: #74AE50;
        filter: drop-shadow(0px 2px 10px rgba(255, 255, 255, 0.15));
    }

    h2 {
        margin: 0;
    }

    .best-price {
        background-color: white;
        width: 100%;
        color: black;
        border-radius: 10px;
        position: relative;
        padding: 20px 35px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        font-size: 1.45rem;

        @media screen and (min-width: 768px) {
            flex-direction: row;
        }

        margin: 35px 0;

        .label {
            position: absolute;
            top: 0;
            left: 15px;
            transform: translateY(-50%);

            padding: 5px 25px;
            border-radius: 25px;
            background-color: #74AE50;

            color: white;
            font-size: 1rem;
        }

        .price {
            font-size: 2rem;
        }
    }
}

.section-contact {
    justify-content: center;
    align-items: center;
    display: flex;
}

footer {
    background: hsla(213, 100%, 2%, 1);
}

@import "styles/forms.scss";

@keyframes rotate-spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

$spinner-size: 75px;

.spinner {
    border-radius: 50%;
    border: 8px solid #fff;
    border-right-color: transparent;
    border-left-color: transparent;
    height: $spinner-size;
    width: $spinner-size;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-name: rotate-spinner;
    animation-timing-function: linear;
}

.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem 0;
}

.icon {
    $size: 18px;
    width: $size;
    height: $size;
    margin: 0 2px;
}

.section-submited {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    margin: 1rem 0;
    text-align: center;
}

@media screen and (min-width: 768px) {
    .mobile-only {
        display: none;
    }
}

@import "./styles/overlay.scss";