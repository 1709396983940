@use "sass:math";

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #010f1e;
    flex-direction: column;
    display: none;
    opacity: 0;
    transition: 1s;

    // backdrop-filter: blur(24px);

    padding: 20px;

    &__logo {
        width: 100%;
        padding: 0 4rem;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;
            max-width: 350px;
        }
    }

    &__content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;

        h1,
        h2,
        h3 {
            margin: .5rem;
        }

        h1 {
            font-size: 3rem;
        }

        h3 {
            font-size: 1.5rem;
        }

        p {
            margin: .25rem;
        }

        .divider {
            border-top: 2px solid white;
            margin: 2rem 0;
            width: 50%;
        }
    }

    &__close {
        $size: 50px;
        position: absolute;
        top: math.div($size, 2);
        right: math.div($size, 2);
        width: $size;
        height: $size;

        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 2px solid #fff;
        padding: math.div($size, 8);
        opacity: .55;
        transition: .25s;
        cursor: pointer;
        overflow: hidden;

        &:hover {
            opacity: 1;
        }
    }

    &.visible {
        display: flex;
    }

    &.open {
        opacity: 1;
    }
}