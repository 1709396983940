* {
  box-sizing: border-box;
}

html {
  padding: 0;
  margin: 0;
  height: 100%;
}

body {
  background: #010f1e;
  color: white;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  position: relative;
  font-family: "Roboto", sans-serif;
}

.header {
  display: flex;
  justify-content: center;
  padding: 1rem 0 0 0;
}
.header__container {
  max-width: 960px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  gap: 20px;
  align-items: center;
}
@media screen and (min-width: 567px) {
  .header__container {
    grid-template-columns: repeat(12, 1fr);
  }
}
.header__logo {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 1/-1;
}
@media screen and (min-width: 567px) {
  .header__logo {
    grid-column: 2/5;
    grid-row: 1;
  }
}
.header__about {
  grid-column: 1/-1;
  display: flex;
  justify-content: center;
}
@media screen and (min-width: 567px) {
  .header__about {
    grid-column: 5/9;
    grid-row: 1;
  }
}
.header__contacts {
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: auto auto;
  grid-auto-rows: min-content;
  gap: 0.25rem 0.75rem;
}
@media screen and (min-width: 567px) {
  .header__contacts {
    grid-column: 8/-1;
    grid-row: 1;
  }
}
.header__contacts .contacts__icons {
  display: flex;
  justify-content: end;
}
.about__link {
  border-bottom: 4px solid transparent;
  transition: 0.25s;
  padding-bottom: 2px;
  cursor: pointer;
}
.about__link:hover {
  border-bottom: 4px solid #0e83fa;
}

.footer {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  padding: 1.75rem 0;
  gap: 1rem;
}
@media screen and (min-width: 768px) {
  .footer {
    grid-template-columns: repeat(3, 1fr);
  }
}
.footer__contacts {
  display: grid;
  grid-template-columns: auto auto;
  grid-auto-rows: min-content;
  gap: 0.25rem 0.75rem;
}
.footer__contacts .contacts__icons {
  display: flex;
  justify-content: end;
}
.footer__copyright {
  grid-column: 1/-1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

main {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  justify-items: center;
  margin-bottom: 180px;
}
main section {
  padding: 0 20px;
}

.section-title {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: 135px;
}
.section-title__title {
  font-size: 12vw;
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .section-title__title {
    font-size: 4rem;
  }
}
.section-title__subtitle {
  font-size: 2rem;
}

.section-subtitle {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.section-subtitle h2 {
  font-size: 2rem;
}

.section-form {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  gap: 15px;
}
@media screen and (min-width: 768px) {
  .section-form {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 768px) {
  .section-form .form-description {
    grid-column: 1/3;
  }
}

.section--container {
  max-width: 900px;
  width: 100%;
  position: relative;
}

.section--wide {
  width: 100%;
}

.section--end {
  margin-bottom: 125px;
}

.section-best-price {
  margin-top: 65px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section-best-price hr {
  width: 65%;
  margin: 35px 0;
  border-color: #74AE50;
  filter: drop-shadow(0px 2px 10px rgba(255, 255, 255, 0.15));
}
.section-best-price h2 {
  margin: 0;
}
.section-best-price .best-price {
  background-color: white;
  width: 100%;
  color: black;
  border-radius: 10px;
  position: relative;
  padding: 20px 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 1.45rem;
  margin: 35px 0;
}
@media screen and (min-width: 768px) {
  .section-best-price .best-price {
    flex-direction: row;
  }
}
.section-best-price .best-price .label {
  position: absolute;
  top: 0;
  left: 15px;
  transform: translateY(-50%);
  padding: 5px 25px;
  border-radius: 25px;
  background-color: #74AE50;
  color: white;
  font-size: 1rem;
}
.section-best-price .best-price .price {
  font-size: 2rem;
}

.section-contact {
  justify-content: center;
  align-items: center;
  display: flex;
}

footer {
  background: #00050a;
}

.input-container {
  font-family: "Montserrat", sans-serif;
}
.input-container label {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.input-container > label > div {
  margin-top: 5px;
  background-color: white;
  color: black;
  border-radius: 5px;
  font-size: 1.25rem;
  display: flex;
  flex-grow: 1;
}
.input-container__input {
  padding: 15px;
  position: relative;
  height: 56px;
  box-sizing: border-box;
}
.input-container__input .input__prefix {
  flex-grow: 0;
  display: flex;
  align-items: center;
}
.input-container__input .input__suffix {
  flex-grow: 0;
  display: flex;
  align-items: center;
}
.input-container__input input {
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 1.25rem;
  padding: 0;
  margin: 0;
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  height: 100%;
  border-top: 1px solid transparent;
}
.input-container__select {
  padding: 15px;
}
.input-container__select select {
  border: 0;
  outline: 0;
  flex-grow: 1;
  background-color: transparent;
  height: 100%;
  font-size: 1.25rem;
  margin: 0;
}
.input-container--btn-fix {
  display: flex;
  flex-direction: column;
}
.input-container--btn-fix::before {
  display: block;
  content: "fix";
  visibility: hidden;
  color: transparent;
}
.input-container__btn {
  width: 100%;
  height: 56px;
  padding: 0 35px;
  outline: none;
  border-radius: 5px;
  border: none;
  margin-top: 5px;
  background-color: #0E83FA;
  color: white;
  font-size: 1.05rem;
  transition: 0.2s;
}
.input-container__btn:hover {
  cursor: pointer;
  filter: drop-shadow(0px 2px 10px rgba(255, 255, 255, 0.15));
}
.input-container__btn:disabled {
  background-color: gray;
}

.input-volume {
  width: 100%;
  position: absolute;
  top: calc(100% - 10px);
  left: 0;
  z-index: 10;
  box-shadow: 0px 3px 3px #010f1e40;
  display: none;
  flex-direction: column;
  background-color: white;
  border-radius: 0 0 5px 5px;
}
.input-volume--open {
  display: grid;
  grid-template-columns: auto;
  padding: 0.5rem;
  gap: 0.5rem;
}
.input-volume--open input[type=number] {
  border: 1px solid #010f1e;
  padding: 0.5rem;
  border-radius: 4px;
}
.input-volume-value {
  display: flex;
}
.input-volume-value-sep {
  flex-grow: 1;
}
.input-volume__ok-btn {
  margin: 5px 0;
  background-color: #0E83FA;
  padding: 1rem;
}

@keyframes rotate-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinner {
  border-radius: 50%;
  border: 8px solid #fff;
  border-right-color: transparent;
  border-left-color: transparent;
  height: 75px;
  width: 75px;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: rotate-spinner;
  animation-timing-function: linear;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
}

.icon {
  width: 18px;
  height: 18px;
  margin: 0 2px;
}

.section-submited {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  margin: 1rem 0;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .mobile-only {
    display: none;
  }
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #010f1e;
  flex-direction: column;
  display: none;
  opacity: 0;
  transition: 1s;
  padding: 20px;
}
.overlay__logo {
  width: 100%;
  padding: 0 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.overlay__logo img {
  width: 100%;
  max-width: 350px;
}
.overlay__content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: justify;
}
.overlay__content h1,
.overlay__content h2,
.overlay__content h3 {
  margin: 0.5rem;
}
.overlay__content h1 {
  font-size: 3rem;
}
.overlay__content h3 {
  font-size: 1.5rem;
}
.overlay__content p {
  margin: 0.25rem;
}
.overlay__content .divider {
  border-top: 2px solid white;
  margin: 2rem 0;
  width: 50%;
}
.overlay__close {
  position: absolute;
  top: 25px;
  right: 25px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid #fff;
  padding: 6.25px;
  opacity: 0.55;
  transition: 0.25s;
  cursor: pointer;
  overflow: hidden;
}
.overlay__close:hover {
  opacity: 1;
}
.overlay.visible {
  display: flex;
}
.overlay.open {
  opacity: 1;
}
/*# sourceMappingURL=index.622f5928.css.map */
