.header {
    display: flex;
    justify-content: center;
    padding: 1rem 0 0 0;

    &__container {
        max-width: 960px;
        width: 100%;

        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: min-content;

        @media screen and (min-width: 567px) {
            grid-template-columns: repeat(12, 1fr);

        }

        gap: 20px;

        align-items: center;
    }

    &__logo {
        display: flex;
        align-items: center;
        justify-content: center;

        grid-column: 1/-1;

        @media screen and (min-width: 567px) {
            grid-column: 2/5;
            grid-row: 1;
        }
    }

    &__about {
        grid-column: 1/-1;

        @media screen and (min-width: 567px) {
            grid-column: 5/9;
            grid-row: 1;
        }

        display: flex;
        justify-content: center;
    }

    &__contacts {
        grid-column: 1/-1;

        display: grid;
        grid-template-columns: auto auto;
        grid-auto-rows: min-content;
        gap: .25rem .75rem;



        @media screen and (min-width: 567px) {
            grid-column: 8/-1;
            grid-row: 1;
        }


        .contacts {
            &__icons {
                display: flex;
                justify-content: end;
            }

            &__text {}
        }
    }

}

.about__link {
    $size: 4px;

    border-bottom: $size solid transparent;
    transition: .25s;
    padding-bottom: 2px;
    cursor: pointer;

    &:hover {
        border-bottom: $size solid #0e83fa;
    }
}