$border-radius: 5px;

.input-container {

    font-family: 'Montserrat', sans-serif;

    label {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    $fontsize: 1.25rem;

    &>label>div {
        margin-top: 5px;
        background-color: white;
        color: black;
        border-radius: $border-radius;
        font-size: $fontsize;
        display: flex;
        flex-grow: 1;
    }

    &__input {
        padding: 15px;
        position: relative;
        height: 56px;
        box-sizing: border-box;

        .input {
            &__prefix {
                flex-grow: 0;
                display: flex;
                align-items: center;
            }

            &__suffix {
                flex-grow: 0;
                display: flex;
                align-items: center;
            }
        }

        input {
            outline: none;
            border: none;
            background-color: transparent;
            font-size: $fontsize;
            padding: 0;
            margin: 0;
            flex-grow: 1;
            flex-shrink: 1;
            width: 100%;
            height: 100%;
            border-top: 1px solid transparent;
        }

    }

    &__select {
        padding: 15px;

        select {
            border: 0;
            outline: 0;
            flex-grow: 1;
            background-color: transparent;
            height: 100%;
            font-size: $fontsize;
            margin: 0;

        }



    }

    &--btn-fix {
        display: flex;
        flex-direction: column;

        &::before {
            display: block;
            content: "fix";
            visibility: hidden;
            color: transparent;
        }
    }

    &__btn {
        width: 100%;
        height: 56px;
        padding: 0 35px;
        outline: none;
        border-radius: $border-radius;
        border: none;
        margin-top: 5px;

        background-color: #0E83FA;
        color: white;
        font-size: 1.05rem;


        transition: .2s;

        &:hover {
            cursor: pointer;
            filter: drop-shadow(0px 2px 10px rgba(255, 255, 255, 0.15));
        }

        &:disabled {
            background-color: gray;
        }
    }
}

.input-volume {
    width: 100%;
    position: absolute;
    top: calc(100% - 10px);
    left: 0;
    z-index: 10;
    box-shadow: 0px 3px 3px #010f1e40;

    display: none;
    flex-direction: column;

    background-color: white;
    border-radius: 0 0 $border-radius $border-radius;

    &--open {
        display: grid;
        grid-template-columns: auto;
        padding: .5rem;
        gap: .5rem;

        input[type="number"] {
            border: 1px solid #010f1e;
            padding: .5rem;
            border-radius: 4px;
        }
    }

    &-value {
        display: flex;

        &-sep {
            flex-grow: 1;
        }
    }

    &__ok-btn {
        margin: 5px 0;
        background-color: #0E83FA;
        padding: 1rem;
    }
}