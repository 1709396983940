.footer {
    display: grid;
    grid-template-columns: 1fr;

    @media screen and (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
    }

    align-items: center;

    padding: 1.75rem 0;
    gap: 1rem;

    &__contacts {
        display: grid;
        grid-template-columns: auto auto;
        grid-auto-rows: min-content;
        gap: .25rem .75rem;

        .contacts {
            &__icons {
                display: flex;
                justify-content: end;
            }

            &__content {}
        }

    }

    &__copyright {
        grid-column: 1/-1;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}